<template>
  <div class="large-screen-container flex flex-col">
    <Header title="河南智慧林业综合平台"></Header>
    <div class="body flex">
      <div class="content">
        <div class="left">
          <div class="left-top">
            <div class="left-top-title" @click="getData('',0)">
              火情信息
            </div>
            <div class="section" @click="getData('',0)">
              <div class="hqxx-section">
                <div class="hqxx-section-label">
                  <i class="hqxx-section-label-icon"></i>
                  <span class="hqxx-section-label-title">当日火情数（起）</span>
                </div>
                <div class="hqxx-sction-info">
                  <div class="hqxx-section-info-cell" v-for="(item,i) in drhqsArr" :key="i">
                    {{item != '-' ? item :''}}
                  </div>
                </div>
              </div>
              <div class="hqxx-section">
                <div class="hqxx-section-label">
                  <i class="hqxx-section-label-icon"></i>
                  <span class="hqxx-section-label-title">本年度火情总数（起）</span>
                </div>
                <div class="hqxx-sction-info">
                  <div class="hqxx-section-info-cell" v-for="(item,i) in bndhqzsArr" :key="i">
                    {{item != '-' ? item :''}}
                  </div>
                </div>
              </div>
            </div>
            <div class="pie-section">
              <pie-chart :datas="pieDatas"></pie-chart>
            </div>
          </div>
          <div class="left-bottom">
            <div class="left-bottom-title">
              防火资源统计（个）
            </div>
            <div class="left-bottom-wrap">
              <div class="left-bottom-item" v-for="(item,i) in list" :key="i" @click="getData(item,1)">
                <div class="item-top">
                  <i :class="['item-icon', item.icon]"></i>
                  <span>{{item.name}}</span>
                </div>
                <div class="item-num">
                  {{item.num}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <MapChart ref="mapChartRef" @initMap="initMap" @checkMap="getMapData"></MapChart>
        </div>
        <div class="right">
<!--          <div class="right-top">-->
<!--            <div class="right-top-title">-->
<!--              火险预警-->
<!--            </div>-->
<!--            <div :class="['hx-item', item.type]" v-for="(item, index) in hxList" :key="index">-->
<!--              <div class="hx-item-top">-->
<!--                {{item.title}}-->
<!--              </div>-->
<!--              <div class="hx-item-bottom">-->
<!--                <div class="hx-item-bottom-item">-->
<!--                  <div class="hx-item-bottom-item-label">-->
<!--                    市-->
<!--                  </div>-->
<!--                  <div class="hx-item-bottom-item-num">-->
<!--                    {{item.city}}-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="hx-item-bottom-item">-->
<!--                  <div class="hx-item-bottom-item-label">-->
<!--                    县-->
<!--                  </div>-->
<!--                  <div class="hx-item-bottom-item-num">-->
<!--                    {{item.county}}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="right-bottom">
            <div class="right-bottom-title" @click="getData('',2)">
              火灾数据
            </div>
            <div class="bar-chart">
              <BarChart :datas="barDatas"></BarChart>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <Navigation></Navigation>
      </div>
    </div>
  </div>
</template>

<script>
import flexs from "@/mixins/flexs.js";
import PieChart from "./components/pie-chart";
import MapChart from "./components/map-chart";
import Header from '../../components/header';
import BarChart from './components/bar-chart';
import Navigation from '../../components/navigation'
import {getPageInfo,getSiteNumGroupByRegion,getFireNumGroupByRegion,getFireAreaGroupByRegion,getDetailListApi} from '@/api/integratedPlatform'
export default {
  data() {
    return {
      drhqs: '169',
      bndhqzs: '1185',

      pieDatas: [
          { value: 52, name: "电话报警" },
          { value: 86, name: "巡护员报警" },
          { value: 985, name: "卫星报警" },
          { value: 185, name: "视频监控报警" },
      ],
      list:[
        {
          icon: 'phd',
          name: '扑火队',
          num: 151,
          siteType:1,
        },{
          icon: 'jcz',
          name: '检查站',
          num: 230,
          siteType:2,
        },{
          icon: 'gld',
          name: '隔离带',
          num: 0,
          siteType:3,
        },{
          icon: 'wzk',
          name: '物资库',
          num: 77,
          siteType:4,
        },{
          icon: 'qsd',
          name: '取水点',
          num: 74,
          siteType:5,
        },{
          icon: 'xcbp',
          name: '宣传碑牌',
          num: 332,
          siteType:6,
        },{
          icon: 'txss',
          name: '通讯设施',
          num: 73,
          siteType:7,
        },{
          icon: 'lwt',
          name: '瞭望塔',
          num: 86,
          siteType:8,
        },
      ],
      hxList:[
        {
          type: 'max',
          title: '极度危险',
          city: 0,
          county: 1
        },{
          type: 'high',
          title: '高度危险',
          city: 2,
          county: 1
        },{
          type: 'higher',
          title: '较高危险',
          city: 0,
          county: 1
        },
      ],
      barDatas:{
        legend: ["森林火灾次数", "受害森林面积"],
        xaxis: ["2019","2020","2021","2022","2023"],
        dataArr: [
          [99, 50, 80,  44, 398],
          [0.07, 0.015, 0.022, 0.013, 0.035],
        ]
      },
      mapDatas: {

      },
      dataInfo:{},
      mapRowData:{},
      siteType:null,
      title:'年度火情总数',
      mapDataList:[],
      mapType:0,
      detailList:[]
    };
  },
  components: {
    PieChart,
    MapChart,
    Header,
    BarChart,
    Navigation,
  },
  computed: {
    drhqsArr(){
      return this.convertToArr(this.drhqs)
    },
    bndhqzsArr(){
      return this.convertToArr(this.bndhqzs)
    }
  },
  mounted(){
    this.getPageInfoFun()
    this.getFireNumGroupByRegionFun()
    // this.getDetailListApiFun()
  },
  methods: {

    //  清空
    initMap(){
      this.mapRowData=''
      this.siteType=null
      this.title='年度火情总数'
      this.getFireNumGroupByRegionFun()
    },
    //点击地图下级
    getMapData(e){
      if(e){
        this.mapRowData=e
        if(this.siteType!=null){
          this.getSiteNumGroupByRegionFun()
        }else{
          this.getFireNumGroupByRegionFun()
        }
        this.getDetailListApiFun()
      }else{
        this.mapRowData={}
        if(this.siteType!=null){
          this.getSiteNumGroupByRegionFun()
        }else{
          this.getFireNumGroupByRegionFun()
        }
      }
    },
    //根据区划分组查询站点数量
    getSiteNumGroupByRegionFun(){
      let data={
        siteType:this.siteType,
        regionName:this.mapRowData.name?this.mapRowData.name:'',
      }
      getSiteNumGroupByRegion(data).then(res=>{
        if(res.code==200){
          this.mapDataList=res.data
          this.$refs.mapChartRef.setData(this.mapDataList,this.title)
        }
      })
    },
    // 根据区划分组查询火灾面积
    getFireAreaGroupByRegionFun(){
      getFireAreaGroupByRegion().then(res=>{
        if(res.code==200){
          this.mapDataList=res.data
          this.$refs.mapChartRef.setData(this.mapDataList,this.title)
        }
      })
    },
    //根据区划分组查询火灾数量
    getFireNumGroupByRegionFun(){
      let data={
        regionName:this.mapRowData.name?this.mapRowData.name:'',
      }
      getFireNumGroupByRegion(data).then(res=>{
        if(res.code==200){
          this.mapDataList=res.data
          this.$refs.mapChartRef.setData(this.mapDataList,this.title)
        }
      })
    },
    //地图坐标数据
    getDetailListApiFun(){
      let data={
        city:this.mapRowData.name?this.mapRowData.name:'',
      }
      getDetailListApi(data).then(res=>{
        if(res.code==200){
          res.data.forEach(item=>{
            item.value=[item.longitude,item.latitude]
          })
          this.detailList=res.data
          setTimeout(()=>{
            this.$refs['mapChartRef'].reInit(this.detailList)
          },200)
        }
      })
    },
    getData(e,type){
      this.siteType=e.siteType
      this.mapType=type
      if(type==0){//火情信息
        this.title='年度火情总数'
        this.getFireNumGroupByRegionFun()
      }else if(type==1){//防火资源
        this.title=e.name
        this.getSiteNumGroupByRegionFun()
      }else if(type==2){//火灾数据
        this.title='火灾面积'
        this.getFireAreaGroupByRegionFun()
      }

    },
    getPageInfoFun(){
      getPageInfo().then(res=>{
        if(res.code==200){
          this.dataInfo=res.data
          this.drhqs=res.data.todayFireNum.toString()
          this.bndhqzs=res.data.yearFireNum.toString()
          let numClassifyArr=Object.keys(res.data.numClassify).map(key => ({ name:key, value: res.data.numClassify[key] }));
          this.pieDatas=numClassifyArr
          let arr = Object.keys(res.data.siteNum).map(key => ({ key, value: res.data.siteNum[key] }));
          this.list.forEach(item=>{
            arr.forEach(i=>{
              if(i.key==item.name){
                item.num=i.value
              }
            })
          })
          let yearList=[],numList=[],areaList=[]
          res.data.timesByYear.forEach((item,index)=>{
            yearList.push(item.year)
            numList.push(item.num)
            areaList.push(res.data.areaByYear[index]&&res.data.areaByYear[index].area?res.data.areaByYear[index].area:0)
          })
          this.barDatas.xaxis=yearList
          this.barDatas.dataArr=[numList,areaList]
        }
      })
    },
    convertToArr(_str){
      const maxLen = 8
      let _arr = Array.apply(null, Array(10)).map(function() { return '-'; });
      const _beginIndex = maxLen - _str.length
      _arr.splice(_beginIndex)
      _arr = [..._arr, ..._str.split('')]
      return _arr
    },
  },
  mixins: [flexs],
};
</script>
<style lang="scss">
@import "../../assets/css/index.scss";
</style>
<style lang="scss" scoped>
.large-screen-container {
  height: 100%;
  overflow: hidden;
  background: url('../../assets/images/ls-bg.png') center center no-repeat;
  background-size: 100% 100%;
  padding: 0 35px 39px 59px;
  position: relative;


  .body{
    height: calc(100% - 102px);
    width: calc(100% - 112px);
    position: absolute;
    left: 53px;
    top: 80px;
    display: flex;
    flex-direction: column;
    .content{
      flex: 1;
      display: flex;
      .left{
				text-align: left;
        width: 437px;
        .left-top{
					height: 550px;
          background: url('./images/hq_bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .left-top-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
					.hqxx-section{
						margin-top: 13px;
						margin-left: 35px;
						margin-right: 20px;

						.hqxx-section-label{
							display: flex;
              align-items: center;
							.hqxx-section-label-icon{
								display: block;
								width: 24px;
								height: 24px;
								margin-right: 14px;
								background: url("./images/wxmhqzsicon@2x.png") center center no-repeat;
								background-size: 100% 100%;
							}
							.hqxx-section-label-title{
								flex: 1;
								height: 17px;
								line-height: 1;
								font-family: Microsoft YaHei;
								font-weight: 400;
								font-size: 16px;
								color: #FFFFFF;
								text-align: left;
							}
						}
            .hqxx-sction-info{
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
              .hqxx-section-info-cell{
                width: 30px;
                height: 43px;
                background: #0D1732;
                border: 1px solid #2D80EC;
                line-height: 43px;
                font-family: PangMenZhengDao;
                font-weight: 400;
                font-size: 30px;
                color: #FC2626;
                text-align: center;
              }
            }
					}
          .pie-section{
            flex:1;
          }
				}
        .left-bottom{
          margin-top: 8px;
					height: 335px;
          background: url('./images/left-bottom-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .left-bottom-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .left-bottom-wrap{
            padding: 7px 20px 0 23px;
            display: flex;
            flex-wrap: wrap;
            .left-bottom-item{
              border: 1px solid #062E56;
              width: 120px;
              height: 83px;
              overflow: hidden;
              border-radius: 2px;
              background: url('./images/left-bottom-item-bg.png') center center no-repeat;
              margin: 10px 0 0 10px;
              display: flex;
              flex-direction: column;
              text-align: center;
              justify-content: space-evenly;
              .item-top{
                height: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                .item-icon{
                  width: 15px;
                  height: 15px;
                  display: inline-block;
                  margin-right: 7px;
                  &.phd{
                    background: url('./images/phd.png') center center no-repeat;
                    background-size: 100% 100%;
                  }
                  &.jcz{
                    background: url('./images/jcz.png') center center no-repeat;
                    background-size: 100% 100%;
                  }
                  &.gld{
                    background: url('./images/gld.png') center center no-repeat;
                    background-size: 100% 100%;
                  }
                  &.wzk{
                    background: url('./images/wzk.png') center center no-repeat;
                    background-size: 100% 100%;
                  }
                  &.qsd{
                    background: url('./images/qsd.png') center center no-repeat;
                    background-size: 100% 100%;
                  }
                  &.xcbp{
                    background: url('./images/xcbp.png') center center no-repeat;
                    background-size: 100% 100%;
                  }
                  &.txss{
                    background: url('./images/txss.png') center center no-repeat;
                    background-size: 100% 100%;
                  }
                  &.lwt{
                    background: url('./images/lwt.png') center center no-repeat;
                    background-size: 100% 100%;
                  }
                }

              }
              .item-num{
                  height: 20px;
                  line-height: 20px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  font-size: 24px;
                  color: #69FFFA;
                }
            }
          }
        }
      }
      .right{
        width: 437px;
        .right-top{
					height: 439px;
          background: url('./images/right-top-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .right-top-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .hx-item{
            height: 106px;
            margin: 14px 20px 0 38px;
            position: relative;
            padding: 40px 15px 10px 15px;
            &:first-of-type{
              margin-top: 34px;
            }
            &.max{
              background: url('./images/max.png') center center no-repeat;
              background-size: 100% 100%;
              .hx-item-bottom-item{
                background: url('./images/max-item.png') center center no-repeat;
                background-size: 100% 100%;
                .hx-item-bottom-item-num{
                  color: #FF2323;
                }
              }
            }
            &.high{
              background: url('./images/high.png') center center no-repeat;
              background-size: 100% 100%;
              .hx-item-bottom-item{
                background: url('./images/high-item.png') center center no-repeat;
                background-size: 100% 100%;
                .hx-item-bottom-item-num{
                  color: #F58D2D !important;
                }
              }
            }
            &.higher{
              background: url('./images/higher.png') center center no-repeat;
              background-size: 100% 100%;
              .hx-item-bottom-item{
                background: url('./images/higher-item.png') center center no-repeat;
                background-size: 100% 100%;
                .hx-item-bottom-item-num{
                  color: #FFF223 !important;
                }
              }
            }
            .hx-item-top{
              position: absolute;
              left: 38px;
              top: 10px;
              height: 16px;
              line-height: 1;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 16px;
              color: #FFFFFF;
            }
            .hx-item-bottom{
              display: flex;
              justify-content: space-between;
              .hx-item-bottom-item{
                width: 165px;
                height: 55px;
                padding: 11px;
                display: flex;
                align-items: center;
                .hx-item-bottom-item-label{
                  width: 16px;
                  height: 16px;
                  line-height: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #FFFFFF;
                }
                .hx-item-bottom-item-num{
                  flex: 1;
                  text-align: center;
                  line-height: 16px;
                  height: 17px;
                  font-family: PangMenZhengDao;
                  font-weight: 400;
                  font-size: 24px;
                  color: #FF2323;
                }
              }
            }
          }
        }
        .right-bottom{
          margin-top: 16px;
					height: 439px;
          background: url('./images/right-top-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .right-bottom-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .bar-chart{
            flex: 1;
          }
        }
      }
      .center{
        flex: 1;
      }
    }
    .bottom{
      height: 50px;
      margin-top: 13px;
    }
  }
}
</style>
