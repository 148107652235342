<template>
  <div class="bar-chart-container" :id="id">
    <!-- 折线图 -->
  </div>
</template>
<script>
export default {
  name: "barChart",
  props: {
    id: {
      type: String,
      default: function () {
        return "bar-chart-" + this.$utils.uuid();
      },
    },
    chartOpt: {
      type: Object,
    },
    datas:{
      type: Object
    }
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          width: 1000,
          show: true,
          right: 'center',
          textStyle: {
            color: "#E7F8FB",
            fontWeight: 400,
            fontSize: '12px',
            fontFamliy: 'Microsoft YaHei'
          },
        },
        grid: {
          show: true,
          left: "20px",
          right: "20px",
          bottom: "20px",
          containLabel: true,
          borderColor: '#404756'
        },
        xAxis: {
          axisTick: {
            show: false
          },
          z: 10,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#404756",
              type: "solid",
              width: 2,
            },
          },
          axisLabel: {
            color: "#E7F8FB",
            fontSize: "12px",
            interval: 0,
          },
        },
        yAxis: [{
          name: "单位：起",
          nameTextStyle: {
            color: '#E7F8FB',
            fontFamliy: 'Microsoft YaHei',
            fontWeight: 400,
            fontSize: '12px'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#E7F8FB'
          },
          splitLine: {
            lineStyle: {
              type: "sold",
              color: "#404756",
            },
          },
        },{
          name: "单位：万公顷",
          nameTextStyle: {
            color: '#E7F8FB',
            fontFamliy: 'Microsoft YaHei',
            fontWeight: 400,
            fontSize: '12px'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#E7F8FB'
          },
          splitLine: {
            lineStyle: {
              type: "sold",
              color: "#404756",
            },
          },
        }],
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            showBackground: false,
            yAxisIndex: 0,
            barWidth : 20,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#66A6FF' },
                { offset: 1, color: '#052650' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#66A6FF' },
                  { offset: 1, color: '#052650' }
                ])
              }
            },
          },{
            name: '受害森林面积',
            type: 'line',
            yAxisIndex: 1,//对应的yAxis对应的索引
            itemStyle: { color: '#F58D2D' },
            smooth: true,
          },
        ]
      },
      barChart: null,
    };
  },
  mounted() {
    const chartDom = document.getElementById(this.id);
    this.barChart = this.$echarts.init(chartDom);
    this.draw();
  },
  watch: {
    datas: {
      handler: function (val) {
        if (val) {
          this.draw();
        }
      },
      deep: true,
    },
  },
  methods: {
    draw() {
      if(this.option.legend){
        this.option.legend.data = this.datas.legend
        this.option.legend.show = this.datas.legendShow == undefined ? true : this.datas.legendShow
      }
      if(this.datas?.yAxis?.name ){
        this.option.yAxis.name = this.datas.yAxis.name 
      }
      
      this.option.xAxis.data = this.datas.xaxis
      this.option.series.map((item, index) => {
        item.name = this.datas.legend[index]
        item.data = this.datas.dataArr[index]
        if(this.datas.stack){
          item.stack = this.datas.stack
        }
      })
      this.option.series = this.option.series.filter(item => item.data)
      this.barChart.setOption(this.option);
    },
  },
};
</script>
<style lang="scss" scoped>
.bar-chart-container {
  width: 100%;
  height: 100%;
}
</style>